<template>
  <!--modals-->
  <div id="contact-modal" class="container py-5 my-3">
    <div
      class="modal interstitial"
      id="interstitial-team-modal"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
      data-backdrop="static"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div>
            <div class="row contact-modal-header py-4 px-1 px-sm-4">
              <h5 class="col-10 modal-title" id="myModalLabel">
                Contact the Patient Advocacy Grants Team
              </h5>
              <div class="col-2 px-lg-0 px-md-0 px-sm-4">
                <div
                  class="contact-btn-link"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                ></div>
              </div>
            </div>
            <div class="modal-body py-4 px-4">
              <div class="row">
                <div class="col-8">
                  <p>
                    For questions about the grant process or activities, please
                    reach out to your Merck U.S. Patient Advocacy contact.
                  </p>
                  <p>
                    For questions about the grant management system software
                    (i.e. passwords, locked account) please contact our vendor
                    <a
                      href="https://msd.versaic.com/faq"
                      class="content-link tablink-technical"
                      target="_blank"
                      rel="noopener"
                      id="interstitial-team-target"
                    >
                      here</a
                    >.
                  </p>
                </div>
                <div class="col-4">
                  <img
                    alt
                    class="img-fluid ps-2"
                    src="@/assets/images/contact-modal.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end modals-->
</template>

<script>
export default {
  name: "ContactModal",
  props: {
    msg: String,
  },
  mounted() {
    // When clicking the link inside the modal,
    $("#interstitial-team-modal #interstitial-team-target").click(function () {
      // Close the contact modal after opening a new tab.
      // This allow user to continue using FAQ page without modal errors.
      $('#interstitial-team-modal .contact-btn-link').after().click();
    });
  },
};
</script>
