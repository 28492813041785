<template>
  <div class="faq-accordions">
    <div class="row">
      <div class="col-lg-3 col-md-4" id="sidebarTabs">
        <div
          class="tabbed-list nav nav-tab d-block"
          id="v-tab-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            :class="[
              this.currTab === 'technical'
                ? `${this.defaultTabClasses} active`
                : `${this.defaultTabClasses}`,
            ]"
            id="v-tab-tech-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-tab-tech"
            tab-target="technical"
            type="button"
            role="tab"
            aria-controls="v-tab-tech"
            aria-selected="true"
          >
            Technical
          </button>
          <button
            :class="[
              this.currTab === 'eligibility'
                ? `${this.defaultTabClasses} active`
                : `${this.defaultTabClasses}`,
            ]"
            id="v-tab-eligibility-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-tab-eligibility"
            tab-target="eligibility"
            type="button"
            role="tab"
            aria-controls="v-tab-eligibility"
            aria-selected="false"
          >
            Eligibility
          </button>
          <button
            :class="[
              this.currTab === 'submissions'
                ? `${this.defaultTabClasses} active`
                : `${this.defaultTabClasses}`,
            ]"
            id="v-tab-prepare-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-tab-prepare"
            tab-target="submissions"
            type="button"
            role="tab"
            aria-controls="v-tab-prepare"
            aria-selected="false"
          >
            Prepare to submit your application
          </button>
          <button
              :class="[
              this.currTab === 'review'
                ? `${this.defaultTabClasses} active`
                : `${this.defaultTabClasses}`,
            ]"
            class="sidetab nav-link"
            id="v-tab-review-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-tab-review"
            tab-target="review"
            type="button"
            role="tab"
            aria-controls="v-tab-review"
            aria-selected="false"
          >
            Review and approval process
          </button>
          <button
            :class="[
              this.currTab === 'changes'
                ? `${this.defaultTabClasses} active`
                : `${this.defaultTabClasses}`,
            ]"
            id="v-tab-process-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-tab-process"
            tab-target="changes"
            type="button"
            role="tab"
            aria-controls="v-tab-process"
            aria-selected="false"
          >
            Changes to your program
          </button>
          <button
            :class="[
              this.currTab === 'outcomes'
                ? `${this.defaultTabClasses} active`
                : `${this.defaultTabClasses}`,
            ]"
            id="v-tab-outcomes-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-tab-outcomes"
            tab-target="outcomes"
            type="button"
            role="tab"
            aria-controls="v-tab-outcomes"
            aria-selected="false"
          >
            Outcomes
          </button>
          <button
            :class="[
              this.currTab === 'reconciliation'
                ? `${this.defaultTabClasses} active`
                : `${this.defaultTabClasses}`,
            ]"
            id="v-tab-reconciliation-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-tab-reconciliation"
            tab-target="reconciliation"
            type="button"
            role="tab"
            aria-controls="v-tab-reconciliation"
            aria-selected="false"
          >
            Reconciliation
          </button>
        </div>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="tab-content" id="v-tab-tabContent">
          <!--TECHNICAL-->
          <div
            class="tab-pane fade show active"
            id="v-tab-tech"
            role="tabpanel"
            aria-labelledby="v-tab-tech-tab"
          >
            <accordions>
              <accordion title="I forgot my password, how can I reset it?">
                <p>
                  Please click "Forgot your Password" on the
                  <a
                    href="http://msdgrant.com"
                    class="interstitial-link content-link"
                    data-bs-toggle="modal"
                    data-bs-target="#interstitial-modal"
                    >msdgrant.com</a
                  >
                  homepage to reset your password.
                </p>
              </accordion>
              <accordion
                title="Who can I contact for issues logging into my account?"
              >
                <p>
                  Please reach out to your Merck USPA contact for login issues.
                </p>
              </accordion>
              <accordion
                title="Who can I contact for application and submission issues?"
              >
                <p>
                  Please reach out to your Merck USPA contact for application
                  and submission issues.
                </p>
              </accordion>
              <accordion
                title="There is a new contact at my organization who will be submitting and managing our grants. Am I able to update my account email to theirs or transfer the account?"
              >
                <p>
                  Please reach out to your Merck USPA contact to have the
                  requests transferred.
                </p>
              </accordion>
            </accordions>
          </div>
          <!--END TECHNICAL-->

          <!--ELIGIBILITY-->
          <div
            class="tab-pane fade"
            id="v-tab-eligibility"
            role="tabpanel"
            aria-labelledby="v-tab-eligibility-tab"
          >
            <accordions>
              <accordion title="What is grant funding at Merck?">
                <p>
                  We believe that supporting non-profit organizations through
                  grants and charitable contributions is a responsible and
                  important way that we can help address global health
                  challenges.
                </p>
                <p>
                  Merck Sharp &amp; Dohme (MSD), known as Merck &amp; Co., Inc.
                  in the U.S. and Canada, offers support through a variety of
                  grant and charitable contribution programs, each of which has
                  its own focus and application process.
                </p>
                <p>
                  U.S. Patient Advocacy (USPA) supports U.S.-based patient
                  advocacy educational initiatives and charitable fundraisers
                  for our therapeutic areas of interest. The grants we support
                  are independent and follow internal and external compliance
                  guidelines, rules, and regulations.
                </p>
              </accordion>
              <accordion title="Who can apply for Patient Advocacy Grants?">
                <p>
                  Non-profit U.S.-based organizations focused on patient
                  advocacy or patient education, including patient organizations
                  and patient advocacy groups.
                </p>
              </accordion>
              <accordion
                title="Who can apply for Fundraising Charitable Grants?"
              >
                <p>
                  Non-profit U.S.-based patient or advocacy organizations whose
                  primary purpose is to raise funds to support the charitable
                  mission of the organization.
                </p>
              </accordion>
              <accordion title="What is a Patient Advocacy Grant?">
                <p>
                  Patient Advocacy Grants are independent grants that may be
                  given to U.S.-based non-profit patient organizations that
                  focus on improving patient health and quality of health care
                  through education and programming. This includes:
                </p>
                <ul>
                  <li>Increase disease awareness</li>
                  <li>Improve patient education and patient care</li>
                  <li>Improve societal access to medicines and vaccines</li>
                  <li>Reduce health disparities</li>
                </ul>
              </accordion>
              <accordion title="What is a Fundraising Charitable Grant?">
                <p>
                  Fundraising Charitable Grants are independent grants that may
                  be given to non-profit charitable organizations or
                  institutions to support fundraising. The fundraising must be
                  for U.S. healthcare-related charitable causes or other
                  community-based charitable fundraising initiatives.
                </p>
              </accordion>
              <accordion
                title="What are the eligibility criteria for Patient Advocacy Grants?"
                question-target="ecpag"
              >
                <p>
                  Organization: Eligible applicants may be non-profit (501(c)3
                  or 501(c)6 registered), U.S.-based organizations focused on
                  patient advocacy or patient education, including:
                </p>
                <ul>
                  <li>Patient and advocacy organizations</li>
                  <li>
                    Professional societies conducting a patient advocacy program
                  </li>
                  <li>
                    Eligible organizations must have a demonstrated track record
                    of success in implementing patient advocacy activities
                    similar to those they are requesting funding for.
                  </li>
                </ul>
                <p>
                  Program: We will consider requests for patient advocacy,
                  education, awareness, and support initiatives that align with
                  one of Merck’s therapeutic areas of interest. Activities may
                  include live and virtual programs or symposiums, podcasts,
                  webcasts, newsletters, print materials, social media and
                  website content, or videos.
                </p>
              </accordion>
              <accordion
                title="What are the eligibility criteria for Fundraising Charitable Grants?"
                question-target="ecfcg"
              >
                <p>
                  Organization: Fundraising Charitable Grants are independent
                  grants that may be given to non-profit charitable
                  organizations or institutions to support fundraising. The
                  fundraising must be for U.S. healthcare-related charitable
                  causes or other community-based charitable fundraising
                  initiatives.
                </p>
                <p>
                  Event: Requests must be in support of U.S. healthcare-based
                  programs or events that align with one of Merck’s therapeutic
                  areas of interest. Fundraising events may include but are not
                  limited to runs, walks, or galas.
                </p>
              </accordion>
              <accordion
                title="What may disqualify a Patient Advocacy request from being funded?"
                question-target="dpar"
              >
                <p>
                  Examples that may disqualify a Patient Advocacy request from
                  being funded include but are not limited to:
                </p>
                <ul>
                  <li>
                    Requests from healthcare providers (HCPs), HCP groups, other
                    organizations that sell, dispense, prescribe, reimburse for,
                    or distribute our company’s products in the United States or
                    their affiliated foundations
                  </li>
                  <li>
                    Application is submitted less than 60 days before the
                    program start date
                  </li>
                  <li>
                    Support is sought for developing materials that have already
                    been developed
                  </li>
                  <li>
                    Support is sought for a program that has already happened
                  </li>
                  <li>
                    Program is for accredited continuing medical education.
                    Merck offers support to eligible organizations for
                    accredited medical education
                    <a
                      href="https://msdimegrants.com/"
                      class="content-link interstitial-link"
                      data-bs-toggle="modal"
                      data-bs-target="#interstitial-modal"
                      target="_blank"
                      >here</a
                    >
                  </li>
                  <li>
                    Program relates to a disease area or healthcare topic that
                    differs from our company’s therapeutic areas of interest
                  </li>
                  <li>
                    Funding is for gifts, travel, lodging, or registration fees
                    for people to attend the program
                  </li>
                  <li>
                    Program is more social or recreational than educational
                  </li>
                  <li>
                    Request is for a
                    <router-link
                      class="content-link"
                      :to="{
                        name: 'grant-types',
                        query: { section: 'fcg' },
                      }"
                       target="_blank"
                      >fundraising charitable event</router-link
                    >
                  </li>
                  <li>
                    Program involves a religious, political, lobbying,
                    fraternal, or social organization or person
                  </li>
                  <li>
                    Speaker names or our company’s product or generic names are
                    listed in the grant application
                  </li>
                  <li>
                    An application has been previously submitted for the same
                    program taking place in the same calendar year
                  </li>
                  <li>
                    Program will be held at a lavish (such as resorts, islands,
                    beaches, and lavish restaurants and hotels) venue or a venue
                    that may distract the educational quality of the program
                    (such as theme parks, national parks, casinos,
                    hunting/fishing lodges, and music/sporting/theatre events)
                  </li>
                </ul>
              </accordion>
              <accordion
                title="What may disqualify a Fundraising Charitable Event request from being funded?"
                question-target="dfce"
              >
                <p>
                  Examples that may disqualify a Fundraising Charitable Event
                  request from being funded include but are not limited to:
                </p>
                <ul>
                  <li>
                    Requests from healthcare providers (HCPs), HCP groups, other
                    organizations that sell, dispense, prescribe, reimburse for,
                    or distribute our company’s products in the United States or
                    their affiliated foundations
                  </li>
                  <li>
                    Application is submitted less than 60 days before the event
                    start date
                  </li>
                  <li>
                    Support is sought for an event that has already happened
                  </li>
                  <li>
                    Program is for accredited continuing medical education.
                    Merck offers support to eligible organizations for
                    accredited medical education
                    <a
                      href="https://msdimegrants.com/"
                      class="content-link interstitial-link"
                      data-bs-toggle="modal"
                      data-bs-target="#interstitial-modal"
                      target="_blank"
                      >here</a
                    >
                  </li>
                  <li>
                    Program relates to a disease area or healthcare topic that
                    differs from our company’s therapeutic areas of interest
                  </li>
                  <li>
                    Funding is for gifts, travel, lodging, or registration fees
                    for people to attend the program
                  </li>
                  <li>
                    Request is for a
                    <router-link
                      class="content-link"
                      :to="{
                        name: 'grant-types',
                        query: { section: 'pag' },
                      }"
                       target="_blank"
                      >patient advocacy educational program</router-link
                    >
                  </li>
                  <li>
                    The main purpose of the request is not to raise funds for a
                    healthcare or healthcare-related topic
                  </li>
                  <li>
                    Program involves a religious, political, lobbying,
                    fraternal, or social organization or person
                  </li>
                  <li>
                    Speaker names or our company’s product or generic names are
                    listed in the grant application
                  </li>
                  <li>
                    An application has been previously submitted for the same
                    program taking place in the same calendar year
                  </li>
                </ul>
              </accordion>
              <accordion
                title="Can I submit for a past program or a program that has started?"
              >
                <p>
                  No, we are unable to support past programs or programs that
                  have already started. We will only consider applications that
                  are submitted at least 60 days before the program start date
                  to allow sufficient review time.
                </p>
              </accordion>
              <accordion title="What is the submission deadline?">
                <p>
                  Completed applications must be submitted at least 60 days
                  before the program start date to allow sufficient review time.
                </p>
              </accordion>
              <accordion
                title="Is there a limit to the amount of funding or number of requests one organization can submit?"
              >
                <p>
                  There is no limit to the number of requests one organization
                  can submit.
                </p>
              </accordion>
              <accordion
                title="What other areas of Merck are responsible for grant funding?"
              >
                <p>
                  Merck offers support to eligible organizations for accredited
                  medical education
                  <a
                    href="https://msdimegrants.com/"
                    class="content-link interstitial-link"
                    data-bs-toggle="modal"
                    data-bs-target="#interstitial-modal"
                    target="_blank"
                    >here</a
                  >
                  and for charitable programs
                  <a
                    href="https://www.msd.com/company-overview/responsibility/"
                    class="content-link interstitial-link"
                    data-bs-toggle="modal"
                    data-bs-target="#interstitial-modal"
                    target="_blank"
                    >here</a
                  >.
                </p>
              </accordion>
              <accordion
                title="What are Merck’s therapeutic areas of interest?"
                question-target="taoi"
              >
                <div class="row pt-3">
                  <div class="col-md-6">
                    <ul class="conditions">
                      <li>Cardiovascular</li>
                      <li>Diabetes</li>
                      <li>Heart Failure</li>
                      <li>Hepatology - MASH</li>
                      <li>HIV/AIDS</li>
                      <li>Human papillomavirus (HPV)</li>
                      <li>Immunization – Adult</li>
                      <li>Immunization – Pediatric (for children)</li>
                      <li>Immunology</li>
                      <li>Infectious Disease</li>
                      <li>Pulmonary Arterial Hypertension</li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="conditions">
                      <li>
                        Oncology (cancer)
                        <ul>
                          <li>Bladder Cancer</li>
                          <li>Breast Cancer</li>
                          <li>Cervical Cancer</li>
                          <li>Colorectal Cancer</li>
                          <li>Endometrial Cancer (uterine)</li>
                          <li>Esophageal Cancer</li>
                          <li>Gastric Cancer (stomach)</li>
                          <li>Head and Neck Cancer</li>
                          <li>Hematologic/Blood Cancers</li>
                          <li>Hepatocellular Carcinoma (liver)</li>
                          <li>Melanoma Cancer (skin)</li>
                          <li>Non-Small Cell Lung Cancer</li>
                          <li>Ovarian Cancer</li>
                          <li>Pancreatic Cancer</li>
                          <li>Pan-Tumor (Immuno-Oncology)</li>
                          <li>Prostate Cancer</li>
                          <li>Renal Cell Carcinoma (kidney)</li>
                          <li>Small Cell Lung Cancer</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </accordion>
            </accordions>
            <!--END ELIGIBILITY-->
          </div>

          <div
            class="tab-pane fade"
            id="v-tab-prepare"
            role="tabpanel"
            aria-labelledby="v-tab-prepare-tab"
          >
            <!--Prepare to submit your application-->
            <accordions>
              <accordion
                title="May I speak with someone about my grant submission?"
              >
                <p>
                  Yes. Please contact your Merck USPA contact for any questions
                  about or issues with your submission.
                </p>
              </accordion>
              <accordion
                title="May I complete part of my application and return to it later?"
              >
                <p>
                  Yes. If you can’t complete your application in one sitting,
                  you may save and return to it at another time by clicking
                  "Save &amp; Close" at the bottom of the page.
                </p>
              </accordion>
              <accordion
                title="May I use a past application for a new request, to save time when listing our organization’s information?"
              >
                <p>
                  Yes, you are able to duplicate a past application by clicking
                  the “Duplicate” button to the right of that request on your
                  homepage once you are logged in.
                </p>
              </accordion>
              <accordion
                title="How do I know if I chose the correct grant type?"
              >
                <p>
                  Please read the definition of each grant type when applying
                  carefully before proceeding to ensure you select the one most
                  aligned with your request. Choosing the incorrect grant type
                  may lead to your request being declined.
                </p>
              </accordion>
              <accordion
                title="My disease or therapeutic area is not listed as an area of interest, but I know Merck has it in Clinical Trials, what should I do?"
              >
                <p>
                  Although your disease or therapeutic area may not be currently
                  listed, please check back for updates.
                </p>
              </accordion>
              <accordion title="What is considered a third-party entity?">
                <p>
                  A third-party entity is any company or entity that is
                  contributing to your program or activity with a direct written
                  contract to provide products or services on your
                  organization's behalf. All third parties must adhere to Merck
                  policies and guidance.
                </p>
              </accordion>
              <accordion
                title="How do I know if my application was submitted successfully?"
              >
                <p>
                  You will get a confirmation email from MSD Grant Portal (<a
                    class="content-link"
                    href="mailto:noreply@system.versaic.com"
                    >noreply@system.versaic.com</a
                  >)
                </p>
                <p>
                  You will get important emails from this address – please add
                  <a
                    class="content-link"
                    href="mailto:noreply@system.versaic.com"
                    >noreply@system.versaic.com</a
                  >
                  to your address book or safe sender list so all future emails
                  get to your inbox
                </p>
                <p>
                  You can also log in to check the status of your submission on
                  your homepage of the Grant Portal.
                </p>
              </accordion>
            </accordions>
          </div>
          <!--END Prepare to submit your application-->
          <!--Review and approval process-->

          <div
            class="tab-pane fade"
            id="v-tab-review"
            role="tabpanel"
            aria-labelledby="v-tab-review-tab"
          >
            <accordions>
              <accordion
                title="How long does it take you to review my application once I submit it?"
              >
                <p>
                  Our company gets many requests for support and considers only
                  applications aligned with our areas of interest received at
                  least 60 calendar days before the start date of the proposed
                  activity. This is a competitive process, and your completed
                  application will be evaluated based on a variety of factors.
                </p>
                <p>
                  You will be notified of a decision as soon as possible. We may
                  contact you for more information at any time during our
                  review, and we ask that you return the information we need
                  right away so that this doesn’t delay the process.
                </p>
              </accordion>

              <accordion
                title="The LOA has been signed, how long until we get the approved funding?"
              >
                <p>
                  Payment for your program is expected to be transferred
                  electronically within approximately 2-6 weeks of the fully
                  executed (signed) LOA. The payment will be the approved amount
                  and will be sent to the bank account provided in your
                  application.
                </p>
              </accordion>

              <accordion title="What is the review and approval process?">
                <ol>
                  <li>
                    Once we get your completed application, we review it and we
                    may contact you if we need more information
                  </li>
                  <li>
                    If a decision is made to support your request, you will get
                    a Letter of Agreement (LOA) by email from DocuSign
                  </li>
                  <li>
                    The LOA must be signed by your organization’s authorized
                    signer, as designated in your application, before the start
                    date of your program or event
                  </li>
                  <li>
                    Payment for your program is expected to be transferred
                    electronically within approximately 2-6 weeks of the fully
                    executed (signed) LOA
                  </li>
                  <li>
                    If we are unable to support your request, we will email you
                    that your request has been declined. All decisions are
                    final.
                  </li>
                </ol>
              </accordion>

              <accordion
                title="If my request is approved, are we able to use your company’s logo or an advertisement for program materials, and if so, where can I find one?"
              >
                <p>
                  Yes, please say in your application that you will require a
                  logo or advertisement, and we will send one to you once the
                  Letter of Agreement (LOA) is executed (signed). Or you may
                  reach out to your Merck USPA contact to get a logo or
                  advertisement.
                </p>

                <p>
                  Please ensure meaningful disclosure of support for the
                  program, resource, or activity. A sample of a disclosure
                  statement is:
                </p>
                <p>
                  <em
                    >This program/resource/activity was made possible through an
                    independent grant from Merck &amp; Co., Inc.</em
                  >
                </p>
                <p>
                  For more information, please refer to the Trademark section of
                  the executed (signed) LOA.
                </p>
              </accordion>

              <accordion title="My request was declined – why?">
                <p>
                  We get many requests for support, and each is carefully
                  considered. However, we are only able to fund a portion of
                  these requests based on a variety of factors. All decisions
                  are final.
                </p>
              </accordion>

              <accordion
                title="Are all decisions final? Can I resubmit a request that has been declined?"
              >
                <p>
                  All decisions are final, and we ask that you please do not
                  resubmit any request that is declined during the same calendar
                  year, as the request will be declined again. You may submit a
                  new request for the same program or initiative during a future
                  calendar year.
                </p>
              </accordion>

              <accordion
                title="I received an email that the Letter of Agreement (LOA) was sent via DocuSign but cannot locate the email from DocuSign or the LOA. What should I do?"
              >
                <p>
                  The LOA is only sent to the designated authorized signer.
                  Please check your junk folder for the separate email from
                  DocuSign. If you are still unable to locate the email, please
                  reach out to your Merck USPA contact.
                </p>
              </accordion>

              <accordion
                title="Where can I get a copy of the fully executed (signed) Letter of Agreement?"
              >
                <p>
                  A copy of the Letter of Agreement will be sent from DocuSign
                  when it is executed (signed).
                </p>
              </accordion>
            </accordions>
          </div>

          <!--End Review and approval process-->

          <!--Changes to your process-->
          <div
            class="tab-pane fade"
            id="v-tab-process"
            role="tabpanel"
            aria-labelledby="v-tab-process-tab"
          >
            <accordions>
              <accordion
                title="What should be considered if I need to make a change to a previously approved grant?"
              >
                <p>
                  If there is any change to an approved grant, you must inform
                  us before the start date of the program or event. Examples of
                  such changes are:
                </p>
                <ul>
                  <li>Start or end date</li>
                  <li>Venue or location</li>
                  <li>
                    Program details, objectives, agenda, or activities (such as
                    live or virtual meetings or programs, website content, video
                    content, social media content, podcast content, or
                    newsletter content)
                  </li>
                  <li>Funding use or amount</li>
                  <li>Any change impacting the executed LOA</li>
                </ul>
                <p>
                  Please complete a Change of Scope Form and send it to your
                  Merck USPA contact. We will consider the proposed changes and
                  let you know if we approve or decline the change of scope
                  request. Approved changes may require a revised LOA. Declined
                  requests may require a return of grant funds.
                </p>
              </accordion>
              <accordion
                title="Where can I get the Change of Scope form?"
                question-target="cosf"
              >
                <p>
                  You can find the Change of Scope Form
                  <a
                    class="content-link"
                    href="/forms/USPA-Change-of-Scope-Request-Form.docx"
                    target="_blank"
                    >here</a
                  >.
                </p>
              </accordion>

              <accordion
                title="Can our organization use the grant funds for something outside of the Letter of Agreement that we deem important and necessary?"
              >
                <p>
                  No. Per the Letter of Agreement, funding may only be used in
                  the manner and for the purpose for which the grant is
                  intended, for the program specified in the agreement. Any
                  unused funding provided for the program must be returned
                  during the reconciliation process. While you may not use the
                  leftover funds for another program or initiative, you may
                  submit a separate grant request.
                </p>
              </accordion>

              <accordion
                title="I submitted a request for funding, but we have decided to cancel the program for which we requested funds. How do I tell someone about this?"
              >
                <p>
                  Please tell your Merck USPA contact. The grant request will be
                  cancelled. Previously approved requests may require a return
                  of grant funds.
                </p>
              </accordion>
            </accordions>
          </div>
          <!--END Changes to your process-->
          <!--OUTCOMES-->

          <div
            class="tab-pane fade"
            id="v-tab-outcomes"
            role="tabpanel"
            aria-labelledby="v-tab-outcomes-tab"
          >
            <accordions>
              <accordion title="How do I submit my outcomes report?">
                <p>
                  Once your program ends, you will get an email from the MSD
                  Grant Portal prompting you complete the outcomes form. Please
                  note that outcomes reports are not required for charitable
                  grants.
                </p>
              </accordion>

              <accordion
                title="How long do I have to complete the outcomes form?"
              >
                <p>You must complete the outcomes form in 45 calendar days.</p>
              </accordion>

              <accordion title="Why do I have to submit an outcomes report?">
                <p>
                  Outcomes give important information (such as attendance
                  numbers, change in knowledge, testimonials, and success
                  tactics) that help us understand the impact and effectiveness
                  of the programs we support. It is a chance for your
                  organization to share the success of your program. It also
                  verifies that the funding was used as described in the
                  approved request.
                </p>
              </accordion>

              <accordion
                title="What happens if we don’t complete our outcomes report?"
              >
                <p>
                  If you do not complete the required outcomes form, it may
                  affect your organization’s eligibility for future grant
                  support.
                </p>
              </accordion>

              <accordion
                title="Can I request an extension to complete the outcomes form?"
              >
                <p>
                  Yes, if you need more time, please inform your Merck USPA
                  contact. We will assess your extension request and give the
                  decision to the person who requested it.
                </p>
              </accordion>
            </accordions>
          </div>
          <!--END OUTCOMES-->
          <!--Reconciliation-->
          <div
            class="tab-pane fade"
            id="v-tab-reconciliation"
            role="tabpanel"
            aria-labelledby="v-tab-reconciliation-tab"
          >
            <accordions>
              <accordion
                title="What is required during the reconciliation process?"
              >
                <p>
                  You must complete a reconciliation form for a program that was
                  funded with a Patient Advocacy Grant (it is not required for a
                  Fundraising Charitable Grant). You will confirm that:
                </p>

                <ul>
                  <li>
                    The grant funds were used in agreement with the LOA and not
                    used for any restricted purpose.
                  </li>
                  <li>
                    The program happened and that the entire grant amount was
                    used in the manner and for the purpose it was intended, only
                    for the program specified in the LOA. You must return any
                    unused funds by sending a check to the address on the
                    reconciliation form.
                  </li>
                </ul>
              </accordion>

              <accordion title="How do I return unused funds?">
                <p>
                  Please list the amount of unused funds when you fill out the
                  reconciliation form. Then send a check with that amount to the
                  address on the reconciliation form.
                </p>
              </accordion>

              <accordion title="How long do I have to complete reconciliation?">
                <p>
                  You will have 45 calendar days to complete the reconciliation
                  form and return any unused funds to us.
                </p>
              </accordion>

              <accordion
                title="What will happen if we don’t complete reconciliation?"
              >
                <p>
                  If you do not complete reconciliation, it may affect your
                  organization’s eligibility for future grant support.
                </p>
              </accordion>

              <accordion
                title="Can I ask for an extension to complete reconciliation?"
              >
                <p>
                  Yes, please tell your Merck USPA contact if you need more time
                  to complete reconciliation. We will assess your extension
                  request and will give our decision to the person who requested
                  it.
                </p>
              </accordion>
            </accordions>
          </div>
          <!--END Reconciliation-->
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import Accordions from '@/components/Accordions.vue';
import accordion from '@/components/AccordionItem.vue';

export default {
  name: 'FaqsTabs',
  props: {
    msg: String,
  },
  data() {
    return {
      currTab: undefined,
      defaultTabClasses: 'sidetab nav-link',
    };
  },
  components: {
    //  AppTabs,
    accordion,
    Accordions,
  },
  mounted() {
    const tab = this.$route.query.tab || 'technical';
    if (tab) {
      $('.sidetab').removeClass('active');
      $(`[tab-target=${tab}]`).click();
      this.$nextTick(() => {
        this.currTab = tab;
        $('header').removeClass('sticky-top');
        $("li.nav-item a[href='/faqs']").addClass('router-link-exact-active');
      });
    }
  },
};
</script>
